<template>
  <div class="button-app-porting">
    <div class="button-app unselect" :class="`status-${status('android')}`" @click="clicked('android')">
      <div class="flex-align">
        <img :src="`/static/icon/ic_android${status('android')!==1 ? '_color' : ''}.svg`" alt="android">
        <span><span class="app-title">안드로이드 앱</span>{{ statusText('android') }}</span>
      </div>
      <div class="box-disabled" v-if="service.service_type === 5 && trial"></div>
    </div>
    <div class="button-app unselect" :class="`status-${status('ios')}`" @click="clicked('ios')">
      <div class="flex-align">
        <img src="/static/icon/ic_apple.svg" alt="ios">
        <span><span class="app-title">IOS 앱</span>{{ statusText('ios') }}</span>
      </div>
      <div class="box-disabled" v-if="service.service_type === 5 && trial"></div>
    </div>
    <sweet-modal ref="modal">
      <div class="modal-content">
        <div class="size-14 weight-700">앱포팅을 위해서는 플랜 결제가 필요합니다.</div>
        <img class="img-event"
             src="https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/07259146.png"
             alt="앱포팅 이벤트">
        <button class="button is-primary" @click="clickPlan">플랜 결제하고 앱포팅 할인 받기</button>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from "sweet-modal-vue";
  export default {
    name: 'ButtonAppPorting',
    components: {
      SweetModal
    },
    props: {
      service: {
        type: Object
      },
      trial: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      clickPlan() {
        this.$refs.modal.close();
        this.$emit('plan');
      },
      status(app) {
        if(this.service.app_porting[app].status === 0) {
          return 0;
        }

        let status = this.service[`${app}_status`];
        let name = this.service[`${app}_package_name`];
        if(!status) {
          return 1;
        } else if(status && !name) {
          return 2;
        } else {
          return 3;
        }
      },
      statusText(app) {
        let statusText = {
          0: '신청',
          1: '신청중',
          2: '등록중',
          3: '보기'
        }[this.status(app)];
        if(this.service.service_type === 5 && this.trial) {
          return '신청';
        } else {
          return statusText;
        }
      },
      clicked(app) {
        if(this.isMobile) {
          this.toast('해당 기능은 PC에서 이용가능합니다.');
          return;
        }

        if(this.service.service_type === 5 && this.trial) {
          alert('앱포팅은 스킨 결제이후 신청이 가능합니다.');
          return;
        }

        let status = this.status(app);
        if(status === 0) {
          if(this.trial) {
            this.$refs.modal.open();
          } else {
            this.$emit(app)
          }
        }else if(status === 3){
          let url = {
            android: 'https://play.google.com/store/apps/details?id=',
            ios: 'https://apps.apple.com/kr/app/id'
          }[app] + this.service[`${app}_package_name`];

          const a = document.createElement("a");
          a.href = url;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .button-app-porting
    display flex
    align-items center
    flex-wrap wrap
    gap 0 6px
  .button-app
    width 150px
    height 32px
    border-radius 6px
    display flex
    align-items center
    justify-content center
    color #4a4a4a
    font-size 12px
    position relative
    .flex-align
      gap 0 4px

  .box-disabled
    position absolute
    width calc(100% + 2px)
    height calc(100% + 2px)
    top -1px
    left -1px
    background-color rgba(255, 255, 255, 0.5)
    z-index 1

  .mobile
    .button-app
      width 100%
      flex 1

  .status-0
    border 1px solid #363636
    background-color white
  .status-1
    border 1px solid #eeeeee
    background-color #eeeeee
  .status-2
    border 1px solid #cccccc
    background-color white
  .app-title
    font-weight 600
    margin-right 4px

  .modal-content
    width 100%
    color #363636
    text-align center
    img
      width 100%
      margin 20px 0 32px 0
    .button
      width 100%

</style>
<style lang="stylus">
  .button-app-porting
    .sweet-modal
      width 400px
    .sweet-modal.is-alert .sweet-content
      padding 60px 20px 32px 20px
</style>
