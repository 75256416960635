<template>
  <div class="modal-plan-change-container">
    <sweet-modal ref="modal" overlay-theme="dark"
                 @close="close" hideCloseButton>
      <template v-if="service">
      <div class="plan-modal-title">플랜 {{ service.plan.trial ? '결제' : '변경' }}</div>
      <div class="plan-modal-content">
        <component :is="`plan-change-step${step}`"
                   :service="service"
                   :selectedItem="selectedItem"
                   :accounts="accounts"
                   @next="onNext"
                   @prev="onPrev"
                   @done="onDone"></component>
      </div>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from "sweet-modal-vue";
  import PlanChangeStep1 from "./Step/PlanChangeStep1";
  import PlanChangeStep2 from "./Step/PlanChangeStep2";
  import PlanChangeStep3 from "./Step/PlanChangeStep3";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";

  export default {
    name: "PlanChangeModal",
    mixins: [
      ServiceAdminAPIMixin
    ],
    components: {
      PlanChangeStep1,
      PlanChangeStep2,
      PlanChangeStep3,
      SweetModal
    },
    props: {
      serviceId: {
        type: Number
      }
    },
    data() {
      return {
        step: 1,
        selectedItem: undefined,
        accounts: [],
        service: undefined
      }
    },
    mounted() {
      this.open();
    },
    computed: {

    },
    methods: {
      async open() {
        let res = await this.request.serviceAdmin.get(`launchpack/v1/service/${this.serviceId}/info`);
        this.service = res.data;

        this.$nextTick(()=>{
          setTimeout(()=>{
            this.$refs.modal.open();
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
          },100);
        });
      },
      close() {
        document.getElementsByTagName('html')[0].style.overflow = '';
        if(this.step === 3) {
          this.$emit('done');
        }
        setTimeout(()=>{
          this.step = 1;
          this.$emit('close');
        }, 500);
      },
      onNext(item) {
        if(this.step === 1) {
          this.selectedItem = item;
          this.step++;
        } else if(this.step === 2) {
          this.account(item);
        }
      },
      onPrev() {
        this.step--;
      },
      onDone(res) {
        this.$refs.modal.close();
        if(res === 'info') {
          window.open(`${this.service.domain}/office/admin/service/plan`,'_blank');
        }
      },
      account(item) {
        this.setLoading('결제 진행 중');
        this.request.serviceAdmin.post(`/launchpack/v1/service/${this.service.id}/plan_account`,
        item).then(res => {
          this.clearLoading();
          if(res.status === 200) {
            this.accounts = item.accounts;
            this.accounts = item.accounts.filter(i => i.type !== 'free');
            this.step++;
          }
        }).catch(()=>{
          this.toast('결제 도중 오류가 발생했습니다.');
          this.clearLoading();
        });

      }
    }
  }
</script>

<style lang="stylus" scoped>
  .plan-modal-title
    background-color #242428
    color white
    font-size 16px
    font-weight 500
    padding 16px 24px
  .plan-modal-content
    width 100%
    max-height calc(100vh - 56px)
    overflow-y auto
</style>
<style lang="stylus">
  .modal-plan-change-container
    .sweet-modal-overlay
      -webkit-perspective initial
    .sweet-modal.is-alert
      width 1200px
      max-width 1200px
      overflow hidden
    .sweet-modal.is-alert .sweet-content
      padding 0
</style>
